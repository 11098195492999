import React, { Component } from 'react';

import './index.scss';
import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Layout from 'src/components/layout';
import Disclaimer from '../../components/disclaimer';
import ConsumerPanel from '../../components/consumer-panel';
import ThreeColumnCards from '../../components/three-column-cards';
import { Link } from 'gatsby';
import { TranslatedBlock } from 'src/components/language';
import TwoWorlds from '../../images/Banner_NZ_Vabysmo_Vabysmo Has Arrived.jpg';
import ResultOfTrial from '../../images/result-of-trial.png';
import MobileHeroBanner from '../../images/mobile-home-banner.png';
import Durability from '../../images/durability.png';
import ImproveVision from '../../images/improve-vision.png'; 
import FourMonths from '../../images/4-months.png';
import ConsultDoctor from '../../components/consult-doctor';
import BottleImage from '../../images/Bottle.svg'
const  symbol = '<';

class PatientPage extends Component {
  static contextType = AppStateContext; 
  componentDidMount() {
    this.context.setSection('patient');
    this.context.setPage('home');
  }
 
  handleClickScroll(){
    const element = document.getElementById('vabysmo-main');
    if (element) {
      const yOffset = -130;
      const y= element.getBoundingClientRect().top + window.scrollY + yOffset;
      // 👇 Will scroll smoothly to the top of the next section
      window.scrollTo({top: y, behavior: 'smooth'});
    }
  }

  render() {
    const title = {
      english: 'What is VABYSMO?'
    };
    return (
      <Layout title={title} showNavbar={true} className="patient">
         <section>
          <div className="two-worlds ">
          <picture>
              <source 
                  media="(max-width: 767px)"
                  srcSet={MobileHeroBanner} />
                <img onClick={this.handleClickScroll} onKeyDown={this.handleClickScroll} loading="lazy" src={TwoWorlds} alt="Scroll Down" />
            </picture>
              
          </div>
        </section>
        <section className="homepage-header container-fluid"  id="vabysmo-main">
            <div className="row top-container" >
              <div className="col-12 col-lg-12 col-xl-12 d-flex flex-column">
                <div className="" >
                  <div className="container d-flex flex-column flex-md-row align-items-center top">
                    <div className="d-flex justify-content-center px-5 py-4">
                      <img loading="lazy" src={BottleImage} alt="Injection Bottle" className={'m-auto the-bottle-image'} />
                    </div>
                    <div className="px-2 py-4"> 
                      <TranslatedBlock language="english">
                        <p><b>VABYSMO is a medicine designed to treat eye diseases, including neovascular (wet) age related macular degeneration (wet AMD), diabetic macular oedema (DMO) and macular oedema secondary to retinal vein occlusion (RVO). </b></p>
                        <ul className='about-vabysmo'>
                          <li>It does this by simultaneously targeting two pathways that cause eye disease (VEGF-A and Ang-2)<sup>*</sup>. </li>
                          <li>VABYSMO is injected into your eye by your doctor. (To learn more click <a className="anchor-link" href="/how-it-works/" title="How VABYSMO works">here "<b>How VABYSMO works</b></a>")</li>
                        </ul>
                        <p className='references'>*VEGF-A:Vascular endothelial growth factor A; Ang-2: Angiopoietin-2</p> 
                      </TranslatedBlock>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </section>
        <ConsultDoctor bgColor="blue"/>
        <section className='why-vabysmo-right'>
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3 col-xs-12 py-1 pt-4 ">
                
                  <h2 className="mb-4 mt-3 text-center">Why VABYSMO might be right for you</h2>
                  <div className="row">
                   <div className="col-3"><img loading="lazy" src={FourMonths} alt="months between treatments" className='vabysmo-right'/></div>
                   <div className="col-9"><p>Delivers a chance for up to <span className='highlight-text'>4 months between treatments</span><sup>1, 2</sup></p></div>
                </div>
                <div className="row">
                   <div className="col-3"><img loading="lazy" src={ImproveVision} alt="Improves vision quickly" className='vabysmo-right' /></div>
                   <div className="col-9"><p><span className='highlight-text'>Improves vision quickly</span> with results maintained after 1 year of treatment</p></div>
                </div>
                <div className="row">
                   <div className="col-3"><img loading="lazy" src={Durability} alt="dual-pathway inhibition provides" className='vabysmo-right' /></div>
                   <div className="col-9"><p>VABYSMO's dual-pathway inhibition provides <span className='highlight-text'>faster drying and extended durability</span> compared to aflibercept<sup>3</sup> </p></div>
                   
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='result-of-trial'>
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3 col-xs-12 py-1 ">
                
                  <h3 className="upper-case text-center">Result of clinical trials at year 2<sup>4</sup></h3> 
                  <div className="row">
                   <div className="col-12"><img loading="lazy" src={ResultOfTrial} alt="Result of clinical trials" className='result-img'/></div>
                  </div>
                <div className="row">
                <div className="col-12"><p className='text-center numbers-para1'>At Year 2, 78% of patients treated with VABYSMO were
                able to extend to 3 or 4 months between treatments</p>
                <p className='text-center numbers-para2'>VABYSMO was generally well tolerated, with a
                comparable safety profile to aflibercept.<sup>3</sup></p></div>
                </div>
                <div className="row ">
                <div className="col-12">
                  <p className='numbers-data'>The most serious adverse reactions were uveitis (0.6%), endophthalmitis (0.5%), vitritis (0.3%),
retinal tear (0.2%), rhegmatogenous retinal detachment (0.1%) and traumatic cataract ( { symbol } 0.1%). The
most frequently reported adverse reactions in patients treated with VABYSMO were cataract (13%),
conjunctival haemorrhage (8%), vitreous detachment (5%), IOP increased (4%), vitreous floaters (4%),
eye pain (3%) and retinal pigment epithelial tear (wet AMD only) (3%).<sup>1, 2</sup></p></div>
                </div>
                </div>
                <div className="col-md-10 offset-md-1 col-xs-12 py-1 pt-2 ">
                  <div className="row references">
                   <div className="col-12"><p >
                   References: 1.  Heier, J. S., Khanani, A. M., Quezada Ruiz, et al. Efficacy, durability, and safety of intravitreal faricimab up to every 16 weeks for neovascular age-related macular degeneration (TENAYA and LUCERNE): two randomised, double-masked, phase 3, non-inferiority trials. Lancet 2022: 399(10326): 729–740. 2. Wykoff CC, Abreu F, Adamis AP, et al. Efficacy, durability, and safety of intravitreal faricimab with extended dosing up to every 16 weeks in patients with diabetic macular oedema (YOSEMITE and RHINE): two randomised, double-masked, phase 3 trials. Lancet 2022: 399(10326): 741–755. 3. Khanani AM, et al. Presented at the Angiogenesis, Exudation and Degeneration 2023 Virtual Congress, February 10-11, 2023.4. Beltran B., Swaminathan, B., Patel, V., et al. Efficacy, Safety and Durability of Faricimab in wet AMD: Year 2 Results From the Phase 3 TENAYA and LUCERNE Trials. Presented at the 14th Annual Congress on Controversies in Ophthalmology (COPHy). Lisbon, Portugal. 24-25 March 2023
                  </p></div>
                  </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container three-section-heading">
            <div className="row">
              <h2>Download Patient Resources and Discussion Guides</h2>
            </div>
        </div>
        <ThreeColumnCards />
        

       
       
        <ConsultDoctor bgColor="blue"/>

        
        <Disclaimer />
        <ConsumerPanel/>
      </Layout>
    );
  }
}

export default PatientPage;
