import React from 'react';

import { TranslatableText } from './language';

import './disclaimer.scss';

export default () => (
  <div className="disclaimer container mt-5">
    <TranslatableText
      dictionary={{
        english:
          `The website contains information for educational purposes only. It is not intended to replace the advice of your doctor or other healthcare professionals.
           If you have questions about your treatment or condition, please talk to your doctor.`
         }}
    />
  </div>
);
