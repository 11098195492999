import React from 'react';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Helmet from 'react-helmet';
import SSRPage from 'src/components/ssr-page';
import PatientPage from 'src/pages/home/index';
import ReactGA from "react-ga4";

const isBrowser = typeof window !== 'undefined';

export default class LoginPage extends React.Component {
  static contextType = AppStateContext;
  componentDidMount() {
    ReactGA.initialize("G-EQVB92CVWD");
    this.context.setSection('home');
    this.context.setPage('home');
  }

  render() {
    if (!isBrowser) {
      return <SSRPage noindexMeta={false} />;
    }

    return (
      <>
        <AppStateContext.Consumer>
          {({ _language }) => (
            <Helmet
              title='Welcome | VABYSMO'
              meta={[
                {
                  name: 'description',
                  content:'You have been prescribed VABYSMO. Look at this website to learn more about your medicine and other useful information.'
                },
                {
                 'http-equiv' : 'Content-Security-Policy',
                  content:"script-src 'self' 'unsafe-inline'  https://cdn.cookielaw.org https://www.googletagmanager.com/; "
                }
              ]}
            >
              
              <html lang='en' />
              <link rel='canonical' href='https://vabysmo.co.nz/' />
              <script
                src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                data-language="en-NZ"
                type="text/javascript"
                charset="UTF-8"
                data-domain-script="5d0b62db-363a-48e2-9d72-c09d5036150b" >

              </script>
              <script type="text/javascript">{`function OptanonWrapper() {}`}</script>

              <body />
            </Helmet>
          )}
        </AppStateContext.Consumer>
        <PatientPage />
      </>
    );
  }
}
